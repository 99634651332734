
import axios from "axios";
import { defineComponent, reactive, ref } from "vue";
import { FileItem } from "@/API/types";
import { ImportData, ImportDataTrande } from "@/API/userCar";
import { message } from "ant-design-vue";
import downloadFile from "@/utils/rv/downloadFile";
import isExcel from "@/utils/closing/isExcel";

export default defineComponent({
  emits: ["close-list-visible", "get-modal-info", "init-table"],
  props: {
    isListVisible: {
      type: Boolean,
      require: true,
      default: false,
    },
    showDrawer: {
      default: false,
      type: Boolean,
    },
    make: {
      default: "",
      type: String,
    },
    makeId: {
      default: -1,
      type: Number,
    },
    isSelect: {
      default: 0,
      type: Number,
    },
  },
  setup(props, { emit }) {
    const isCheckingOcr = ref(false);
    const isvisible = (props as any).isListVisible;
    //弹窗初始化
    const modalData = reactive({});

    // 是否显示上传框
    const showUpload = ref(true);
    const percent = ref(0);
    const uploadFlag = ref(true);
    const file = ref();
    const handleClose = () => {
      emit("close-list-visible");
    };
    // 上传配置状态
    const uploadConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          percent.value = complete;
        }
      },
    };
    // 上传请求信息处理
    const uploadRequest = (source: any) => {
      showUpload.value = false;
      if (source.file) {
        console.log(source, "这是source-----------");
        uploadFlag.value = false;
      } else {
        uploadFlag.value = true;
      }
      console.log(source, "这是source");
      file.value = source.file;
      console.log(file.value, "这是文件value");
    };
    const percentStatus = ref<boolean>(false);
    // 重置上传组件
    const resetComponentStatus = () => {
      showUpload.value = true;
      uploadFlag.value = true;
      percent.value = 0;
      percentStatus.value = false;
    };
    //文件上传数量
    const licenseUploadComplete = ref<boolean>(true);
    const licenseCount = ref(0);
    const licenseFile = ref([]);
    const licenseContent = ref<string>("0/0");
    const licenseFileSize = ref<string>("");
    const licenseFormData = new FormData();
    //上传进度条
    const tempLicenseData = ref([]);
    const licenseTit = ref<string>("文件上传");
    const licensePercent = ref<number>(0);
    const licenseFileNum = ref<number>(0);
    const isLicenseSuccess = ref<boolean>(false);
    const licenseProStatus = ref<string>("normal");
    const uploadError = ref<boolean>(false);
    const licenseClickCount = ref<number>(0);
    // const licenseSubSuccess = ref<boolean>(false)
    // 车架号有问题的表格展示
    const tableResult = reactive<any>([]);
    const columnsResult = reactive<any>([
      {
        title: "车架号",
        dataIndex: "vinNo",
        key: "vinNo",
      },
      {
        title: "状态",
        dataIndex: "msg",
        key: "msg",
        slots: {
          customRender: "msg",
        },
      },
    ]);
    // 置换清单or上传清单
    const isTrande = ref<number>(props.isSelect);
    const isTrandeIn = (num: number) => {
      isTrande.value = num;
      tableResult.length = 0; //清空问题表格
      resetComponentStatus(); //切换清单重置上传组件状态
    };
    //下载模板事件
    const downList = () => {
      const params =
        isTrande.value == 0
          ? {
              url: `/rvapi/ucMarketInfo/templateDownload`,
              method: "get",
              fileName: `上传清单模板${+new Date()}.xlsx`,
            }
          : {
              url: `/rvapi/ucReplacementInfo/templateDownload`,
              method: "get",
              fileName: `置换清单模板${+new Date()}.xlsx`,
            };
      downloadFile(params);
    };

    // 立即上传
    const handleUpload = async () => {
      const fileIsExcel = await isExcel(file.value);

      if (!fileIsExcel) {
        message.error("文件类型错误");
        resetComponentStatus();
        return;
      }
      const data = new FormData();
      data.append("file", file.value);
      try {
        percentStatus.value = false;
        const res: any =
          isTrande.value == 0
            ? await ImportData(data, uploadConfig)
            : await ImportDataTrande(data, uploadConfig);
        if (res.code === "0") {
          if (res.data.length === 0) {
            message.success("提交成功");
          }
          // 上传之后重置上传组件状态
          // resetComponentStatus();
          percentStatus.value = false;
          console.log(res, "这是回执信息");
          tableResult.length = 0;
          tableResult.push(...res.data);
        } else {
          // message.error("此Excel中有相同VIN存在.");
          percentStatus.value = true;
          setTimeout(() => {
            // 上传出错重置上传组件状态
            resetComponentStatus();
          }, 1000);
        }
      } catch (e) {
        percentStatus.value = true;
        setTimeout(() => {
          // 上传出错重置上传组件状态
          resetComponentStatus();
        }, 1000);
      }
    };
    const isContinue = ref<boolean>(false);
    const size = ref<number>(10);
    const timer = ref<number>(0);
    let isCancelUpload = false;
    const close = () => {
      emit("init-table", {}); //刷新主表格
      emit("close-list-visible", { isListVisible: false });
      isCancelUpload = false;
      if (isCancelUpload) {
        setTimeout(() => {
          isCancelUpload = false;
        });
        return;
      }
    };

    //预检查
    const beforeUpload = (file: FileItem, fileList: any) => {
      console.log(file, "这是文件内容");
      console.log("-----------");
      console.log(fileList, "这还是文件列表");
      return new Promise((resolve, reject) => {
        const params = reactive({
          noticeType: "",
          content: "",
          btnVisible: false,
        });
        //判断文件格式
        const isJpg = file.type === "image/jpeg";
        const isPdf = file.type === "application/pdf";
        if (!isJpg && !isPdf) {
          params.noticeType = "error";
          params.content = "文件格式错误，请按照JPG PDF 等格式进行文件上传";
          params.btnVisible = false;
          emit("get-modal-info", { modalVisible: true, modalData: params });
          licenseCount.value = 1;
          return reject(false);
        }
        //判断文件大小
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          params.noticeType = "error";
          params.content = "文件大小必须小于5MB!";
          params.btnVisible = false;
          emit("get-modal-info", { modalVisible: true, modalData: params });
          licenseCount.value = 1;
          return reject(false);
        }
        //判断图片数量
        const fileLength = fileList.length;
        if (fileLength > 100) {
          params.noticeType = "error";
          params.content = "文件数量不能大于100张";
          params.btnVisible = false;
          emit("get-modal-info", { modalVisible: true, modalData: params });
          licenseCount.value = 1;
          return reject(false);
        }
        licenseFile.value = fileList;
        tempLicenseData.value = fileList;
        return resolve(true);
      });
    };
    //文件上传进度条显示
    const CANCEL_TOKEN = axios.CancelToken;
    let cancelFunction = () => {
      console.log("no uploading");
    };
    const licenseConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          licensePercent.value = complete;
          licenseContent.value =
            Math.floor(
              (progressEvent.loaded / progressEvent.total) *
                licenseFileNum.value
            ) +
            "/" +
            licenseFileNum.value +
            "（" +
            complete +
            "%完成）";
        }
      },
      cancelToken: new CANCEL_TOKEN((c) => {
        //强行中断请求要用到的，记录请求信息
        cancelFunction = () => {
          isCancelUpload = true;
          c();
        };
      }),
    };
    // 上传报错 关闭弹窗 重置相应数据
    const isResetModal = (param: { continue: boolean }) => {
      const isCloseModal = param.continue;
      if (isCloseModal) {
        if (licenseProStatus.value == "exception") {
          licenseUploadComplete.value = true;
          licenseProStatus.value = "normal";
          licensePercent.value = 0;
          tempLicenseData.value = [];
          licenseFormData.delete("files");
        }
      }
    };

    return {
      columnsResult,
      cancelFunction,
      isCheckingOcr,
      isvisible,
      isTrandeIn,
      isTrande,
      licenseUploadComplete,
      modalData,
      licenseTit,
      licenseContent,
      licenseFileSize,
      licenseConfig,
      licenseFileNum,
      isLicenseSuccess,
      licenseProStatus,
      uploadError,
      timer,
      licenseClickCount,
      isContinue,
      licensePercent,
      isResetModal,
      downList,
      close,
      beforeUpload,
      size,
      uploadConfig,
      handleClose,
      uploadRequest,
      handleUpload,
      showUpload,
      uploadFlag,
      percent,
      percentStatus,
      tableResult,
    };
  },
  beforeUnmount() {
    this.cancelFunction();
    clearInterval(this.timer);
    this.timer = 0;
  },
});
