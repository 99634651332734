import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d689ee4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "tab-handle-box" }
const _hoisted_4 = { class: "tab-btn" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "img-box"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "impact-model",
    visible: _ctx.visible,
    width: 500,
    bodyStyle: { padding: '0 10px 10px' },
    style: {"margin-top":"100px"},
    footer: null,
    centered: "",
    maskClosable: false,
    onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:visible', false)))
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title) + _toDisplayString(_ctx.fileName) + "." + _toDisplayString(_ctx.fileType), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("span", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTab(0))),
              style: _normalizeStyle([{ color: _ctx.isTab == 0 ? 'blue' : '#333' }, {"padding-right":"10px","cursor":"pointer"}])
            }, "上传文件图片", 4),
            _createElementVNode("span", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeTab(1))),
              style: _normalizeStyle([{ color: _ctx.isTab == 1 ? 'blue' : '#333' }, {"cursor":"pointer"}])
            }, "操作记录", 4)
          ]),
          (_ctx.aUrl)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.aUrl,
                download: ""
              }, "下载图片", 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isTab == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_a_table, {
                columns: _ctx.columns,
                pagination: false,
                dataSource: _ctx.dataSource,
                scroll: { y: _ctx.tableHeight }
              }, null, 8, ["columns", "dataSource", "scroll"])
            ]))
          : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewList, (item, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  (item.docType)
                    ? (_openBlock(), _createBlock(_component_a_image, {
                        key: 0,
                        style: {"margin-bottom":"5px"},
                        src: item.imgUrl,
                        alt: "图片",
                        width: "100%"
                      }, null, 8, ["src"]))
                    : (_openBlock(), _createElementBlock("iframe", {
                        key: 1,
                        id: "iframe",
                        src: item.imgUrl,
                        frameborder: "0",
                        class: "pdf-box"
                      }, null, 8, _hoisted_8))
                ]))
              }), 128))
            ], 512)), [
              [_vShow, _ctx.viewList.length]
            ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}