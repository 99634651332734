
import { defineComponent, ref } from 'vue';
import { message } from 'ant-design-vue';
import { appealFileUp, appealFileUpTradeIn } from '@/API/userCar';
import { FileItem } from '@/API/types';

interface FileInfo {
    file: FileItem;
    fileList: FileItem[];
}
export default defineComponent({
    emits: ['update:visible', 'close-view-visible', 'init-table'],
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true,
        },
        isAppealData: {
            type: Object,
            default: () => {
                return {};
            },
            required: true,
        },
    },
    setup(props, { emit }) {
        const fileList = ref<any>([]);
        const loading = ref<boolean>(false);
        const imageUrl = ref<string>('');
        const reason = ref('');

        // 上传前文件校验
        const beforeUpLoadEvent = (file: FileItem, fileLists: any) => {
            // 校验文件名称的正则

            // 校验文件名称的正则
            const regexDJZ = /^SS_DJZ_/g;
            const regexFP = /^SS_FP_/g;
            const regexSFZM = /^SS_SFZM_/g;
            const regexJSHT = /^SS_JSHT_/g;
            const regexXSHT = /^SS_XSHT_/g;
            const regexRZBG = /^SS_RZBG_/g;
            const regexBXBG = /^SS_BXBG_/g;
            const regexQT = /^SS_QT_/g;

            return new Promise((resolve, reject) => {
                const vinForCheck =
                    props.isAppealData.vinNo ?? props.isAppealData.vehicleVinNo;
                // console.log('vinForCheck', vinForCheck);
                const regexVin = new RegExp(vinForCheck); //LE44M8EB0LL024567 const file = 'SS_BXBG_LE44M8EB0LL024567'
                // console.log(
                //     'regexVin.test(file.name as string)',
                //     file.name as string,
                //     regexVin.test(file.name as string)
                // );
                // 判断文件命名方式
                if (
                    !(
                        regexDJZ.test(file.name + '') ||
                        regexFP.test(file.name + '') ||
                        regexSFZM.test(file.name + '') ||
                        regexJSHT.test(file.name + '') ||
                        regexXSHT.test(file.name + '') ||
                        regexRZBG.test(file.name + '') ||
                        regexBXBG.test(file.name + '') ||
                        regexQT.test(file.name + '')
                    ) ||
                    !regexVin.test(file.name as string)
                ) {
                    message.error(
                        '文件命名格式有误，请上传以SS_上传文件类型拼音缩写_VIN，形式开头命名的文件!'
                    );
                    return reject(false);
                }
                //判断文件格式
                const isJpg = file.type === 'image/jpeg';
                const isPdf = file.type === 'application/pdf';
                const isExcel =
                    file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const isDocx =
                    file.type ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

                if (!isJpg && !isPdf && !isExcel && !isDocx) {
                    message.error(
                        '文件格式错误，请按照.jpg  .pdf  .docx  .xlsx 等格式进行文件上传!'
                    );
                    return reject(false);
                }
                //判断文件大小
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    message.error('文件大小必须小于5MB!');
                    return reject(false);
                }
                //判断图片数量
                const fileLength = fileLists.length;
                if (fileLength > 10) {
                    message.error('文件上传数量不能大于10!');
                    return reject(false);
                }
                fileList.value = fileLists;
                return resolve(true);
            });
        };
        const noSub = () => {
            // 不默认提交
            console.log('');
        };
        // 移除文件
        const handleRemove = (file: FileItem) => {
            const index = fileList.value.indexOf(file);
            const newFileList = fileList.value.slice();
            newFileList.splice(index, 1);
            fileList.value = newFileList;
        };
        // 删除项
        const del = (id: number) => {
            fileList.value.forEach((ele: any, i: number) => {
                if (ele.uid == id) {
                    fileList.value.splice(i, 1);
                    return fileList.value;
                }
            });
        };
        // 大小的转换
        const computeSize = (fileSize: string) => {
            const sizeNum = parseFloat(fileSize) / 1024 / 1024;
            const sizeKb = parseFloat(fileSize) / 1024;
            if (sizeNum < 1) {
                return sizeKb.toFixed(2) + 'KB';
            } else {
                return sizeNum.toFixed(2) + 'MB';
            }
        };
        // 申诉保存与提交
        const submit = async (param: any) => {
            // param==1是保存，param==2是提交申诉
            if (!reason.value.trim()) {
                message.error('请填写申诉理由!');
                return false;
            }
            if (!fileList.value.length) {
                message.error('请上传申诉文件!');
                return false;
            }
            try {
                const appealForm = new FormData();
                fileList.value.forEach((item: any) => {
                    appealForm.append('files', item);
                });
                appealForm.append('id', (props as any).isAppealData.id);
                appealForm.append('msg', reason.value);
                appealForm.append('type', param);
                const res: any =
                    (props as any).isAppealData.types == 0
                        ? await appealFileUp(appealForm)
                        : await appealFileUpTradeIn(appealForm);
                //判断返回的code值 除0外都是异常
                if (res.code !== '0') {
                    message.error('上传失败!请重试');
                } else {
                    fileList.value.length = 0;
                    reason.value = '';
                    emit('update:visible', false);
                    message.success('上传成功!');
                }
                emit('init-table', {});
            } catch (e) {
                console.log(e, '上传报错');
                message.error('上传失败!请重试');
            }
        };
        const close = () => {
            emit('init-table', {});
            emit('update:visible', false);
        };
        return {
            close,
            reason,
            fileList,
            handleRemove,
            beforeUpLoadEvent,
            noSub,
            del,
            loading,
            imageUrl,
            submit,
            computeSize,
        };
    },
});
